import request from '../axios'

/**
 *
 */
export function getMenuPage(params) {
  return request({
    url: '/api/topic/menus',
    params,
  })
}

export function getDetailPage(params) {
  return request({
    url: '/api/topic/speeches',
    params,
  })
}

export function getDetail(id) {
  return request({
    url: `/api/topic/speeches/${id}`,
  })
}

export function createDetail(params) {
  return request({
    method: 'post',
    url: '/api/topic/speeches',
    data: params,
  })
}

export function updateDetail(id, params) {
  return request({
    method: 'post',
    url: `/api/topic/speeches/${id}`,
    data: params,
  })
}
export function updateDetailStatus(id) {
  return request({
    method: 'post',
    url: `/api/topic/speeches/${id}/examine`,
    data: { state: 1 },
  })
}

export function deleteDetail(id) {
  return request({
    method: 'post',
    url: `/api/topic/speeches/delete/${id}`,
  })
}

export function getActionPage(params) {
  return request({
    url: '/api/topic/actions',
    params,
  })
}

/**
 * 新增落实情况
 */
export function createAction(id, params) {
  return request({
    method: 'post',
    url: `/api/topic/${id}/actions`,
    data: params,
  })
}

/**
 * 修改落实情况
 */
export function updateAction(id, params) {
  return request({
    method: 'post',
    url: `/api/topic/actions/${id}`,
    data: params,
  })
}

/**
 * 点赞、取消点赞
 */
export function updateFavorite(id) {
  return request({
    method: 'post',
    url: `/api/topic/action/${id}/fabulous`,
  })
}

/**
 * 删除落实
 */
export function deleteAction(id) {
  return request({
    method: 'post',
    url: `/api/topic/actions/delete/${id}`,
  })
}

/**
 * 获取评论列表
 */
export function getCommentPage(params) {
  return request({
    url: '/api/topic/action/comments',
    params,
  })
}

/**
 * 新增评论
 */
export function createComment(id, params) {
  return request({
    url: `/api/topic/action/${id}/comments`,
    method: 'post',
    data: params,
  })
}

/**
 * 删除评论
 */
export function deleteComment(id) {
  return request({
    url: `/api/topic/action/comments/delete/${id}`,
    method: 'post',
  })
}
